import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import mediaqueries from "@styles/media";

import Layout from "@narative/gatsby-theme-novela/src/components/Layout";
import UnorderedList from "@narative/gatsby-theme-novela/src/components/Lists/List.Unordered";
import Section from "@narative/gatsby-theme-novela/src/components/Section";
import SEO from "@narative/gatsby-theme-novela/src/components/SEO";
import Headings from "@narative/gatsby-theme-novela/src/components/Headings";
import { useColorMode } from "theme-ui";

const About = () => {
  const [colorMode] = useColorMode();

  const isDark = colorMode === "dark";

  return (
    <Layout>
      <SEO
        title="About Hassan Djirdeh's Personal Blog"
        description="Details about the author of the blog, Hassan Djirdeh"
      />
      <Section>
        <ListContainer>
          <Headings.h3>Hi 👋🏽 - I'm Hassan Djirdeh.</Headings.h3>
          <p>
            I'm a senior frontend engineer who's worked at different
            organizations to build large production applications at-scale. I've
            also helped thousands of students learn in-depth front engineering
            skills like React, Vue, TypeScript, and GraphQL with educational
            material.
          </p>
          <br />
          <p>
            I'm using this blog to share my knowledge and experience with you.
            If this is something you may find interesting, feel to{" "}
            <SubscribeLinkContainer isDark={isDark}>
              <Link to="/subscribe">subscribe</Link>
            </SubscribeLinkContainer>{" "}
            to the blog/newsletter as well!
          </p>
          <UnorderedList>
            <li>
              <b>Some more details about me:</b>
            </li>
            <li>
              I live in{" "}
              <a href="https://www.toronto.ca/" target="_blank">
                Toronto
              </a>
              .
            </li>
            <li>
              I'm currently a Senior Software Engineer at{" "}
              <a href="https://doordash.com/" target="_blank">
                Doordash
              </a>
              . Previously @{" "}
              <a href="https://shopify.com/" target="_blank">
                Instacart
              </a>{" "}
              and @{" "}
              <a href="https://shopify.com/" target="_blank">
                Shopify
              </a>
              .
            </li>
            <li>
              I've created a fullstack React masterclass called{" "}
              <a href="https://newline.co/tinyhouse" target="_blank">
                TinyHouse
              </a>
              .
            </li>
            <li>
              I've written a book on Vue named{" "}
              <a href="https://newline.co/vue" target="_blank">
                Fullstack Vue
              </a>
              .
            </li>
            <li>
              I've created some spin-off (free) courses called{" "}
              <a href="https://www.newline.co/30-days-of-vue" target="_blank">
                30 Days of Vue
              </a>{" "}
              and
              <a
                href="https://www.newline.co/courses/the-newline-guide-to-building-your-first-graphql-server-with-node-and-typescript/"
                target="_blank"
              >
                {" "}
                Building your first GraphQL Server with Node & TypeScript
              </a>
              .
            </li>
            <li>
              I've written web development related blog posts/tutorials on{" "}
              <a href="https://medium.com/@hassan.djirdeh" target="_blank">
                {" "}
                Medium
              </a>{" "}
              and{" "}
              <a
                href="https://css-tricks.com/author/hassandjirdeh/"
                target="_blank"
              >
                CSS-Tricks
              </a>
              .
            </li>
            <li>
              I've spoken at a few different{" "}
              <a
                href="https://www.youtube.com/results?search_query=hassan+djirdeh"
                target="_blank"
              >
                conferences
              </a>{" "}
              before.
            </li>
            <li>
              I can always be reached by{" "}
              <a href="mailto:hassan.djirdeh@gmail.com" target="_blank">
                mail
              </a>{" "}
              or on{" "}
              <a href="https://twitter.com/djirdehh" target="_blank">
                Twitter
              </a>
              .
            </li>
          </UnorderedList>
        </ListContainer>
      </Section>
    </Layout>
  );
};

export default About;

const ListContainer = styled.div`
  margin-top: 100px;
  color: ${(p) => p.theme.colors.primary};
  ul {
    margin-top: 20px;
    margin-left: 0;
    font-size: 15px;
    max-width: initial;

    padding: 15px 0 0px 26px;

    a {
      color: ${(p) => p.theme.colors.accent};
      font-weight: 600;
    }

    li {
      color: ${(p) => p.theme.colors.primary};
      padding-bottom: 10px;

      ${mediaqueries.tablet`
        padding-left: 0px;
      `};
    }

    li::before {
      left: -25px;
      top: 9px;
      height: 5px;
      width: 5px;
    }
  }
`;

const SubscribeLinkContainer = styled.div<{ isDark: boolean }>`
  display: inline;
  margin-left: auto;

  a {
    color: ${(p) => p.theme.colors.accent};
    text-decoration: none;
    font-weight: 600;
  }

  svg {
    position: relative;
    bottom: 1px;
  }
`;
